const CustomizedAxisTick = ({
  x,
  y,
  payload,
  rotation = '-50',
  dy = 8,
  endAdornment = '',
  dx = 0,
}: any) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={dy}
        dx={dx}
        fontSize="0.7em"
        textAnchor="end"
        fill="#666"
        transform={`rotate(${rotation})`}
      >
        {payload.value} {endAdornment}
      </text>
    </g>
  )
}

export default CustomizedAxisTick
