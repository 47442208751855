import { ApiClient } from '../../../../services'

const client = new ApiClient()

export type GetEstimateOpts = {
  hash: string
  fileId?: string
}

const getEstimateSummary = ({ hash }: GetEstimateOpts) => {
  return client.post(`/public/estimate`, { hash })
}

const getPublicEditedEstimate = (id: any) => {
  return client.get(`/estimate/edited/public/${id}`)
}

const getTransactionsTileDetails = (tile: string[], q = '') => {
  return client.get(
    `/public/transactions/tiles/details?tile=${tile}&query=${q}`,
  )
}

const getFaosTileDetails = (tile: string[], q = '') => {
  return client.get(`/public/faos/tiles/details?tile=${tile}&query=${q}`)
}

const getListingTileDetails = (tile: string[], q = '') => {
  return client.get(`/public/listing/tiles/details?tile=${tile}&query=${q}`)
}

const downloadFile = ({ hash, fileId }: GetEstimateOpts) => {
  return client.downloadFile(`/public/estimate/${hash}/file/${fileId}`)
}

const summaryService = {
  getEstimateSummary,
  getPublicEditedEstimate,
  getTransactionsTileDetails,
  getFaosTileDetails,
  getListingTileDetails,
  downloadFile,
}

export default summaryService
