import { useI18n } from '@popety_io/popety-io-lib'

import { CustomTooltipPriceRoot } from './Graph.style'

const CustomTooltipListingNumber = ({ active, payload, label, color }: any) => {
  const { t } = useI18n()

  if (!active || !payload?.length) return null

  return (
    <CustomTooltipPriceRoot>
      <p style={{ fontSize: '0.9rem' }}>{`${label}:`}</p>
      <p style={{ color, fontSize: '0.8rem' }}>{`${
        payload[0].value
      } ${t('estimate.biens')}`}</p>
    </CustomTooltipPriceRoot>
  )
}

export default CustomTooltipListingNumber
