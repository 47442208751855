/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import summaryService from './summaryService'

export const summaryMapLayers = [
  'land',
  'authorizedLimit',
  'restrictions',
  'buildings',
]

const initialState = {
  loading: false,
  error: false,
  loadEstimation: false,
  estimate: {},
  selectedLayers: summaryMapLayers,
  cityGeoCenter: {},
  cityGeojson: {},
  estimatedEdited: {},
  user: {},
  company: {},
  estimatedId: null,
  theme: null,
  transactionDetail: {},
  office: {},
}

export const getEstimateSummary = createAsyncThunk(
  'summary/getEstimateSummary',
  summaryService.getEstimateSummary,
)

export const getPublicEditedEstimate = createAsyncThunk(
  'estimateLandSummary/getPublicEditedEstimate',
  summaryService.getPublicEditedEstimate,
)

export const getTransactionsTileDetails = createAsyncThunk(
  'summary/getTransactionsTileDetails',
  summaryService.getTransactionsTileDetails,
)

export const downloadFile = createAsyncThunk(
  'summary/downloadFile',
  summaryService.downloadFile,
)

const estimateSummary = createSlice({
  name: 'estimateSummary',
  initialState,
  reducers: {
    updateTheme(state, action) {
      if (action.payload.primary || action.payload.secondary) {
        state.theme = {
          primary: action.payload.primary ? `#${action.payload.primary}` : null,
          secondary: action.payload.secondary
            ? `#${action.payload.secondary}`
            : null,
          fontFamily: action.payload.fontFamily,
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEstimateSummary.pending, (state) => {
        state.loading = true
      })
      .addCase(getEstimateSummary.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        if (
          action.payload?.errors?.length > 0 ||
          data?.value_edited?.shareFor === 'none'
        ) {
          state.loading = false
          state.error = true
          window.location.href = '/link-expired'

          return
        }

        state.estimatedId = data?.id
        state.estimate = data?.value
        state.company = data?.company
        state.office = data?.office
        state.user = { ...data?.user, creationDate: data?.creation_date }
        state.estimatedEdited = data?.value_edited
        state.loading = false
        state.error = false
        state.defaultValue = data.default_value
      })
      .addCase(getEstimateSummary.rejected, (state) => {
        state.loading = false
        state.error = true
        state.estimate = {}
      })
      .addCase(getPublicEditedEstimate.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        state.estimateEdited = data
      })
  },
})

export const estimateSummaryReducer = {
  estimateSummary: estimateSummary.reducer,
}

export const { updateTheme } = estimateSummary.actions
